import React from 'react';
import { Link } from 'gatsby';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import BrandGrid from '../components/BrandGrid/BrandGrid';
import Seo from '../components/Seo';

const ThankYou = () => {
  return (
    <>
      <Seo title="Thank You · Medical Web Experts" canonical="/thank-you/" />
      <BrandGrid colored />
      <div className="d-flex align-items-lg-center full-height ui-layout__main--nmt">
        <Container>
          <Row className="text-center text-lg-start">
            <Col lg={6}>
              <h1 className="h1--not-decorated font-size-xl">
                Thank You for contacting us.
              </h1>
              <p className="my-5 font-size-lg">
                A Medical Web Experts representative will be in touch with you
                within 24 hours.
              </p>
              <Row className="justify-content-center justify-content-lg-start">
                <Col className="col-auto">
                  <Button
                    as={Link}
                    variant="link"
                    to="/"
                    className="text-decoration-none"
                  >
                    Back to homepage
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default ThankYou;
